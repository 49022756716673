<template>
  <div>
    <Modal :options="{width:'40vw',type:'danger'}">
        <div class="title">Eliminar imágen</div>
        <div class="body">
            <p class="text-center mt-4 mb-4">Eliminarás la imágen seleccionada, esta acción no se puede revertir<br/><strong>¿Deseas continuar?</strong></p>
        </div>
        <div class="footer">
            <div class="row">
                <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="eliminar_imagen">Eliminar</button></div>
                <div class="col-sm-2"><button class="btn danger-btn" @click="cerrar_eliminar_imagen">Cancelar</button></div>
            </div>
        </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import apiActivos from "@/apps/garantias/api/activos";

export default {
    components:{
        Modal
    },
    props:{
        imagen_id:{
            type: Number,
            required:true,
        }
    },
    data(){
        return{
            modal_eliminar_imagen: false
        }
    },
    methods:{
        cerrar_eliminar_imagen(){
            this.modal_eliminar_imagen = false;
            this.$emit('cerrar_eliminar_imagen')
        },
        async eliminar_imagen(){
            try {
                await apiActivos.eliminar_imagen(this.imagen_id);

                this.cerrar_eliminar_imagen();
                this.$emit('imagen_guardada');
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        }
    }

}
</script>

<style>

</style>
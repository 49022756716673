<template>
  <div>
    <Modal :options="{width: '80vw',close: true}" @close="cancelar_importacion_imagenes">
        <div class="title">Garantías</div>
        <div class="body">
            <div class="row form-group">
                <!-- <label for="" class="col-form-label col-sm-2">Selecciona una garantía para importar sus imagenes</label> -->
                <div class="col-sm-10 texto-menor offset-1">
                    <div class="row header">
                        <div class="col-sm-10">Información de la garantía</div>
                        <div class="col-sm-2">Seleccionar</div>
                    </div>
                    <template v-if="garantias.length > 0">
                    <div v-for="garantia in garantias" :key="garantia.id" class="row row_observation">
                        <div  class="col-sm-9">
                                <strong>Calle: </strong> {{garantia.calle!== '' ? garantia.calle : 'N/A'}}, 
                                <strong>No exterior: </strong> {{ garantia.numero_ext!== '' ? garantia.numero_ext : 'N/A' }},
                                <strong>Colonia: </strong> {{ garantia.colonia!== '' ? garantia.colonia : 'N/A' }},
                                <strong>Estado: </strong>{{ garantia.estado!== '' ? garantia.estado : 'N/A' }},
                                <strong>CP: </strong> {{ garantia.cp!== '' ? garantia.cp : 'N/A' }}
                        </div>
                        <div class="col-sm-3 text-center"> <input v-model="garantia_seleccionada" class="form-check-input" type="radio" :id="garantia.id" :value="garantia"/></div>
                    </div>
                    </template>
                    <div v-else class="row">
                        <div class="col-sm-12">No hay garantías</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="row">
                <div class="col-sm-5 mt-4"> <strong>Selecciona una garantía para importar sus imagenes</strong></div>
                <div class="col-sm-2 offset-sm-3"><button class="btn btn-block btn-primary" @click="importar_imagenes">Importar Imágenes</button></div>
                <div class="col-sm-2"><button class="btn btn-block btn-danger" @click="cancelar_importacion_imagenes">Cancelar</button></div>
            </div>
        </div>
    </Modal>    

  </div>
</template>

<script>
import Modal from '@/components/Modal'
import apiActivos from '@/apps/garantias/api/activos';

export default {
    components:{
        Modal
    },
    props:{
        info_garantia:{
            type:Object,
            required:true,
        },
        garantias:{
            type: Array,
            required:true
        }
    },
    data(){
        return{
            garantia_seleccionada:null,
        }
    },
    methods:{
        async importar_imagenes(){
            if(this.garantia_id === null)
                return this.$helper.showMessage('Error','Debe seleccionar una garantía','error','alert');
            /**aquí falta mandar los datos para que se haga la importación de las imagenes, después dar aviso para que se obtenga la garantía nuevamente */
            // this.$log.info(this.info_garantia.solicitud_id);
            // this.$log.info(this.garantia_seleccionada.id);
            let data = await apiActivos.importar_imagenes(this.info_garantia.solicitud_id, this.garantia_seleccionada.id);
            // this.$log.info(data);
            // this.$emit('datos_importacion', {importar:this.datos_garantia});
        },
        cancelar_importacion_imagenes(){
            this.$emit('cancelar_importacion_imagenes');
        }
    }
}
</script>

<style lang="scss" scoped>
  .file_list_container {
    overflow: auto;

    .file_list {
      button {
        margin: 3px;
      }
    }

    .file_list:nth-child(2n+1) {
      background-color: #E6E6E6;
    }
  }

  .header {
    background-color: #4D4D4D;
    color: #fff;
    padding: 5px 3px;
  }

  .texto-menor {
    font-size: 0.8em !important;

    button {
      font-size: 0.8em !important;
    }
  }

  .row_observation {
    div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .row_observation:nth-child(2n+1) {
    background-color: #E6E6E6;
  }
</style>
<template>
  <div>
        <div class="row">
            <div class="col-sm-6">
                <fieldset>
                    <legend>Importar Imágenes Originación</legend>
                    <div class="row form-group">
                        <label for="solicitud_id" class="col-form-label col-sm-2">Solicitud ID</label>
			            <div class="col-sm-4"><input v-model="info_garantia.solicitud_id" id="solicitud_id" name="solicitud_id" type="number" class="form-control"></div>
                        <div class="col-sm-6"><button class="btn btn-block btn-secondary"  @click="buscar_garantias" disabled>Buscar Garantía</button></div>
                    </div>
                </fieldset>
            </div>
            <div class="col-sm-6">
                <fieldset>
                    <legend>Subir Imágenes</legend>
                    <div class="row">
                        <div class="col-sm-6 mb-2"> <button class="btn btn-block btn-success" @click="abrir_modal_cargar_imagen">Cargar imagen</button> </div>
                        <div class="col-sm-6 mb-2"> <button class="btn btn-block btn-dark" @click="descargar_imagen">Recargar imágenes</button> </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-sm-12">
                <fieldset>
                    <legend>Imágenes</legend>
                    <div class="row form-group">
                        <div v-for="( tmp_imagen ) in imagenes" :key="tmp_imagen.id"  class="col-sm-2 mb-4 image-box text-center">
                            <img :src="tmp_imagen.file" alt="valio">
            				<span class="box box-show" :title="tmp_imagen.estatus == 1 ? 'Ocultar imagen' : 'Mostrar imagen'" @click="cambiar_visibilidad_imagen(tmp_imagen)"><i v-if="tmp_imagen.estatus == 1" class="gg-eye"></i><i v-else class="gg-eye-alt"></i></span>
				            <span class="box box-edit" title="Editar imágen" @click="editar_imagen(tmp_imagen)"><i class="gg-pen"></i></span>
                            <span class="box box-delete" title="Eliminar imágen" @click="abrir_modal_eliminar_imagen(tmp_imagen.id)"><i class="gg-trash-empty"></i></span>
                            <span class="box-descript">{{ tmp_imagen.descripcion.length > 15 ? tmp_imagen.descripcion.substr(0,15)+'...' : tmp_imagen.descripcion }}</span>
                        </div>
                    </div>
                </fieldset> 
            </div>            
        </div>	

        <CargarImagen 
            :garantia="garantia"
            :garantia_id="garantia_id"
            :imagen="imagen"
            v-if="modal_cargar_imagen"
            @close="cerrar_cargar_imagen"
            @imagen_guardada="actualizar_imagenes"
        />

        <EliminarImagen 
            v-if="modal_eliminar_imagen"
            :imagen_id="imagen_id"
            @cerrar_eliminar_imagen="cancelar_eliminar_imagen"
            @imagen_guardada="actualizar_imagenes"
        />

        <Imagenes 
            v-if="modal_garantias"
            :info_garantia="info_garantia"
            @cancelar_importacion_imagenes="cerrar_modal_garantias"
            :garantias="garantias"
        />
  </div>
</template>

<script>
import CargarImagen from "./CargarImagen";
import EliminarImagen from "./EliminarImagen";
import Imagenes from "./Importaciones/Imagenes";

import apiActivos from "@/apps/garantias/api/activos";
// import apiCreditos from "@/apps/garantias/api/creditos";

export default {
    props:{
        garantia_id:{
            type:Number,
            required:true,
        },
        garantia:null,
    },
    components:{
        CargarImagen, EliminarImagen, Imagenes
    },
    data(){
        return{
            modal_cargar_imagen: false,
            modal_eliminar_imagen:false,
            modal_garantias:false,
            imagenes:[],
            imagen:null,
            imagen_id:null,
            info_garantia: {
					solicitud_id: null,
					garantia_id: null,
				},
            garantias:[],
        }
    },
    methods:{
        cerrar_cargar_imagen(){
            this.imagen = null;
            this.modal_cargar_imagen = false;
        },

        async descargar_imagen(){
            this.imagenes = [];
            this.garantia.imagenes.forEach(async imagen => {
                let res = (await apiActivos.descargar_imagen(imagen.id)).data;
                    imagen.file = URL.createObjectURL(new Blob([res]));
                    this.imagenes.push(imagen);
            });
        },  
        actualizar_imagenes(){
            this.imagen = null;
            this.$emit('actualizar_imagenes');
        },
        async cambiar_visibilidad_imagen(imagen){
            try {
                await apiActivos.editar_imagen(imagen.id, {estatus: (imagen.estatus == 1 ? 0 : 1)});
                this.$emit('cambiar_visibilidad_imagen');
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        editar_imagen(imagen) {
            this.imagen = imagen;
            this.modal_cargar_imagen = true;
        },
        abrir_modal_eliminar_imagen(imagen_id){
            this.modal_eliminar_imagen = true;
            this.imagen_id = imagen_id;
        },
        cancelar_eliminar_imagen(){
            this.modal_eliminar_imagen = false;
        },
        cerrar_modal_garantias(){
            this.modal_garantias = false;
        },
        async buscar_garantias(){
            try {
                if (this.info_garantia.solicitud_id == null || this.info_garantia.solicitud_id == '') 
                    return this.$helper.showMessage('Error','Debes ingresar el ID de la solicitud','error','alert');

                // this.garantias = (await apiCreditos.mostrar_garantias(this.info_garantia.solicitud_id)).data;
                this.garantias = ((await apiActivos.mostrar_garantias(this.info_garantia.solicitud_id)).data.data);
                this.modal_garantias = true
                // this.$log.info(this.garantias);
            }catch(error) {
                this.$log.info('error',error);
                this.$helper.showAxiosError(error,'Error');
            }
        },
        abrir_modal_cargar_imagen(){
            this.$helper.showMessage('No olvides guardar tus cambios','Recuerda guardar los cambios realizados','info','alert');            
            this.modal_cargar_imagen = true;
        }

    },
    watch:{
        garantia(){
             this.descargar_imagen();
        }
    },
    mounted(){
        this.descargar_imagen();
        this.info_garantia.solicitud_id = this.garantia.solicitud_id;
    }
}
</script>

<style lang="scss" scoped>
	.image-box {
		position: relative;
		overflow: hidden;

		.box {
			width: 30px;
			height: 36px;
			background-color: #CCCCCC;
			border: solid 1px #999999;
			padding: 10px;
			cursor:pointer;
			position:absolute;
			top: 9px;
		}

		img {
			max-width: 100%;
			max-height: 100%
		}

		.box-show {
			width: 40px;
			padding-top: 7px;
			padding-left: 7px;
			right: 95px;
		}

		.box-edit {
			right: 60px;

			i {
				margin-top: 4px;
			}
		}

		.box-delete {
			right: 23px;
		}

		.box-descript {
			width: 80%;
			background-color: #CCCCCC;
			border: solid 1px #999999;
			position: absolute;
			bottom: 9px !important;
			left: 27px;
		}
	}

</style>